import React from "react";
import {
  SbBlokData,
  storyblokEditable,
  StoryblokComponent,
} from "gatsby-source-storyblok";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";

interface TableCell {
  _uid: string;
  _editable: string;
  value: string;
  component: string;
}
interface TableRow {
  _uid: string;
  _editable: string;
  value: string;
  component: string;
  body: TableCell[];
}

interface Table {
  thead: TableCell[];
  tbody: TableRow[];
}

interface IDynamicTable {
  content: Table;
}

type Props = {
  blok: SbBlokData & IDynamicTable;
};

function DynamicTable({ blok }: Props) {
  const {
    content: { tbody, thead },
  } = blok;

  return (
    <TableContainer
      {...storyblokEditable(blok)}
      h={["auto", "800px"]}
      overflowY={["visible", "auto"]}
    >
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            {thead.map((th: TableCell) => (
              <Th key={th._uid}>{th.value}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {tbody.map((tr: TableRow) => (
            <Tr>
              {tr.body.map((td) => (
                <Td>{td.value}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export default DynamicTable;
