import React from "react";
import {
  Flex,
  HStack,
  VStack,
  IconButton,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
  ModalHeader,
  BoxProps,
  Heading,
  Link,
  useToken,
} from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { ScrollLink } from "react-scroll";
import scrollTo from "gatsby-plugin-smoothscroll";

import { useSiteMetadata } from "../hooks/useSiteMetadata";
import { SbGatsbyStory } from "gatsby-source-storyblok";
import { StoryblokLink } from "../types/StoryblokLink";
import { getSectionAnchor } from "../lib/utils";

import SmartLink from "./SmartLink";
import { IDynamicSection } from "./DynamicSection";

type Props = {
  sections?: IDynamicSection[];
} & BoxProps;

type MenuItem = {
  text: string;
  link: StoryblokLink;
  _uid: string;
};

interface MainMenuStory extends SbGatsbyStory {
  items?: MenuItem[];
}

const SpyLink = ScrollLink(Link);

function SiteHeader({ sections = [], ...rest }: Props) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const navLinks = sections.map((section) => {
    const anchor = getSectionAnchor(section.title);
    return (
      <SpyLink
        key={anchor}
        href={`#${anchor}`}
        to={anchor}
        smooth={true}
        duration={500}
        spy={true}
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          onClose();
          scrollTo(`#${anchor}`);
        }}
        textStyle="menuItem"
        fontWeight={800}
      >
        {section.title}
      </SpyLink>
    );
  });

  return (
    <>
      <Flex
        position={"relative"}
        justify="space-between"
        align="center"
        zIndex={10}
        direction="row"
        width="100%"
        px={[4, 8]}
        textStyle={"menu"}
        {...rest}
      >
        <SmartLink
          to={{
            cached_url: "/",
            fieldtype: "/",
            linktype: "story",
            id: "home",
            url: "/",
          }}
        >
          <Heading textStyle="uppercaseHeading" fontWeight={800} as="h1">
            Joel Nanos
          </Heading>
        </SmartLink>

        <HStack as="nav" display={["none", "flex"]} spacing={8}>
          {navLinks}
        </HStack>

        <IconButton
          display={["inherit", "none"]}
          colorScheme="brandAlpha"
          aria-label={isOpen ? "Close navigation" : "Open navigation"}
          onClick={onToggle}
          icon={
            isOpen ? (
              <CloseIcon w={"24px"} h={"24px"} color="brandYellow" />
            ) : (
              <HamburgerIcon h="32px" w="32px" color="blackAlpha.800" />
            )
          }
        />
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent background="rgba(0,0,0,0.8)" textStyle={"mobileMenu"}>
          <ModalCloseButton color="brandYellow" h="32px" w="32px" size="32px" />
          <ModalBody py={12}>
            <VStack as="nav" height="100%" align="center">
              {navLinks}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SiteHeader;
