import React, { useCallback, useState } from "react";
import { render } from "storyblok-rich-text-react-renderer";
import { Box, Text, Button } from "@chakra-ui/react";
import richTextResolvers from "../util/richTextResolvers";

type Props = {
  body: {};
  textAlignment?: "left" | "center" | "right";
  truncateAtLine?: number;
  allowExpand?: boolean;
};

function RichText({
  body,
  textAlignment = "left",
  truncateAtLine,
  allowExpand,
}: Props) {
  const [expanded, setExpanded] = useState(false);

  const handleShowMore = useCallback(() => {
    setExpanded(!expanded);
  }, [allowExpand, expanded, setExpanded]);

  const noOfLines = expanded ? undefined : truncateAtLine;
  return (
    <Box textAlign={textAlignment}>
      <Text as="span" noOfLines={noOfLines}>
        {render(body, richTextResolvers)}
      </Text>
      {allowExpand && (
        <Button onClick={handleShowMore} variant="outline">
          Show {expanded ? "less" : "more"}
        </Button>
      )}
    </Box>
  );
}
export default RichText;
