import React, { ReactElement } from "react";
import { Link as CharkraLink } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import { TextType } from "../types/TextType";

type StoryblokLink = {
  cached_url: string;
  fieldtype: string;
  linktype: string;
  id: string;
  url: string;
};

type Props = {
  to: StoryblokLink;
  target?: string | undefined;
  children: TextType;
};

function SmartLink({ to, children, target }: Props) {
  const isStoryblokLink = to.linktype === "story";
  const destination: string = isStoryblokLink ? `/${to.cached_url}` : to.url;
  const internal = target !== "_blank" && /^\/(?!\/)/.test(destination);

  if (internal) {
    return (
      <CharkraLink as={internal ? GatsbyLink : undefined} to={destination}>
        {children}
      </CharkraLink>
    );
  } else {
    return <CharkraLink href={to.cached_url}>{children}</CharkraLink>;
  }
}

export default SmartLink;
