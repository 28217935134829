import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from "gatsby-source-storyblok";

import { Box, useToken, useMediaQuery } from "@chakra-ui/react";
import SliderImage from "./SliderImage";
import "@splidejs/react-splide/css";

import { StoryblokImage } from "../types/StoryblokImage";

type FlexAlignment = "center" | "flex-start" | "flex-end";

type Props = {
  blok: SbBlokData & {
    images: StoryblokImage[];
    fullSizeImage: boolean;
    imageFit?: "contain" | "cover";
    fullScreen?: boolean;
    contentOverlay: SbBlokData[];
    contentOverlayAlign: FlexAlignment;
    contentOverlayJustify: FlexAlignment;
    autoplay: boolean;
    interval: number;
    type: "slide" | "fade";
    showArrows: boolean;
    showPagination: boolean;
    rewind: boolean;
    speed: number;
    allowZoom: boolean;
  };
};

function ImageSlider({ blok }: Props) {
  const {
    contentOverlay = [],
    contentOverlayAlign = "center",
    contentOverlayJustify = "center",
    allowZoom,
  } = blok;

  const [mobileBreakpoint] = useToken("breakpoints", ["sm"]);

  const [isMobile] = useMediaQuery(`(max-device-width: ${mobileBreakpoint})`);
  const isFullScreen = blok.fullScreen && !isMobile;

  return (
    <Box
      {...storyblokEditable(blok)}
      pos="relative"
      width={isFullScreen ? "100vw" : "auto"}
      height={isFullScreen ? "100vh" : "auto"}
    >
      {contentOverlay.length > 0 && (
        <Box
          pos="absolute"
          display="flex"
          zIndex={1}
          alignItems={contentOverlayAlign}
          justifyContent={contentOverlayJustify}
          px={[2, 32]}
          top={0}
          left={0}
          w="100%"
          h="100%"
        >
          {contentOverlay.map((content: SbBlokData) => (
            <StoryblokComponent key={content._uid} blok={content} />
          ))}
        </Box>
      )}
      <Splide
        options={{
          autoplay: blok.autoplay,
          interval: blok.interval,
          type: blok.type,
          width: isFullScreen ? "100vw" : "auto",
          height: isFullScreen ? "100vh" : "auto",
          arrows: blok.showArrows,
          pagination: blok.showPagination,
          rewind: blok.rewind,
          speed: blok.speed,
          rewindSpeed: blok.speed,
          pauseOnHover: false,
        }}
      >
        {blok.images.map((image) => {
          return (
            <SplideSlide key={image.id}>
              <SliderImage
                fullSizeImage={blok.fullSizeImage}
                image={image}
                allowZoom={allowZoom}
              ></SliderImage>
            </SplideSlide>
          );
        })}
      </Splide>
    </Box>
  );
}

export default ImageSlider;
