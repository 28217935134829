import React from "react";
import { Box, Text, Container } from "@chakra-ui/react";

import { useSiteMetadata } from "../hooks/useSiteMetadata";

type Props = {};

function Footer({}: Props) {
  const { title } = useSiteMetadata();
  return (
    <Box textStyle="footer" background="white" p="8" width="100%">
      <Container>
        <Box p={12}>
          <Text as="p" textAlign="center">
            <Text as="span" textAlign="center">
              &copy; {new Date().getFullYear()}{" "}
            </Text>
            <Text as="span" color="blackAlpha.800">
              {" "}
              |{" "}
            </Text>
            <Text as="span">{title}</Text>
          </Text>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
