import React from "react";
import { Container, Heading } from "@chakra-ui/react";
import {
  SbBlokData,
  storyblokEditable,
  StoryblokComponent,
} from "gatsby-source-storyblok";
import { Element } from "react-scroll";

import { getSectionAnchor } from "../lib/utils";

export interface IDynamicSection {
  title: string;
  content: SbBlokData[];
  width?: string;
}

type Props = {
  blok: SbBlokData & IDynamicSection;
};

function DynamicSection({ blok }: Props) {
  const width = !!blok.width ? blok.width.split(",") : "90vw";
  return (
    <Element name={getSectionAnchor(blok.title)}>
      <Container
        maxW={width}
        w={width}
        {...storyblokEditable(blok)}
        sx={{
          scrollMarginTop: ["siteHeaderMobile", "siteHeaderDesktop"],
          paddingTop: ["siteHeaderMobile", "siteHeaderDesktop"],
        }}
      >
        {(blok.content || []).map(
          (component: SbBlokData): JSX.Element => (
            <StoryblokComponent key={component._uid} blok={component} />
          )
        )}
      </Container>
    </Element>
  );
}

export default DynamicSection;
