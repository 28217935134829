import React from "react";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import { getSbImageUrl } from "gatsby-plugin-storyblok-image";

type SEOProps = {
  title?: string;
  description?: string;
  pathname?: string;
  imageUrl?: string;
};

export const SEO: React.FC<React.PropsWithChildren<SEOProps>> = ({
  title,
  description,
  pathname,
  children,
  imageUrl,
}) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
    title: siteTitle,
  } = useSiteMetadata();

  const seo = {
    title: `${title || defaultTitle} | ${siteTitle}`,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
  };

  const sbImageUrl = imageUrl
    ? getSbImageUrl(imageUrl, {
        quality: 80,
        fitIn: true,
        width: 1200,
        height: 627,
      })
    : undefined;

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:creator" content={seo.twitterUsername} />
      <meta name="og:type" content="website" />
      <meta name="og:title" content={seo.title} />
      <meta name="og:description" content={seo.description} />
      {(imageUrl || image) && (
        <meta name="og:image" content={imageUrl || image} />
      )}
      {children}
    </>
  );
};
