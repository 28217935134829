import React, { Fragment } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImage } from "gatsby-plugin-storyblok-image";

type Props = {
  image: {
    alt: string;
    filename: string;
  };
  fullSizeImage: boolean;
  imageFit?: "contain" | "cover";
  allowZoom: boolean;
};

function SliderImage({ fullSizeImage, imageFit, image, allowZoom }: Props) {
  const gatsbyImageData = getGatsbyImage(image.filename, {
    layout: "fullWidth",
    quality: 80,
  });

  return (
    <>
      {gatsbyImageData ? (
        <GatsbyImage
          style={fullSizeImage ? { height: "100%" } : undefined}
          alt={image.alt}
          image={gatsbyImageData}
          objectFit={imageFit}
        />
      ) : null}
    </>
  );
}

export default SliderImage;
