import React from "react";
import { SbBlokData, storyblokInit } from "gatsby-source-storyblok";
import { Box } from "@chakra-ui/react";

import Footer from "./Footer";

import "@fontsource/montserrat";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";

import Grid from "../components/Grid";
import SiteHeader from "../components/SiteHeader";
import DynamicImage from "./DynamicImage";
import TextBlock from "../components/TextBlock";
import ImageSlider from "../components/ImageSlider";
import DynamicEmbeddableContent from "../components/DynamicEmbeddableContent";
import DynamicButton from "../components/DynamicButton";
import DynamicCenter from "../components/DynamicCenter";
import DynamicSection, { IDynamicSection } from "../components/DynamicSection";
import DynamicContainer from "../components/DynamicContainer";
import DynamicTable from "../components/DynamicTable";
import ImageGrid from "../components/ImageGrid";
import DynamicSocialIcons from "./DynamicSocialIcons";

storyblokInit({
  accessToken: process.env.GATSBY_STORYBLOK_TOKEN,
  // bridge: false,
  // apiOptions: {  },
  components: {
    grid: Grid,
    textBlock: TextBlock,
    imageSlider: ImageSlider,
    button: DynamicButton,
    center: DynamicCenter,
    image: DynamicImage,
    embeddableContent: DynamicEmbeddableContent,
    imageGrid: ImageGrid,
    section: DynamicSection,
    container: DynamicContainer,
    table: DynamicTable,
    socialIcons: DynamicSocialIcons,
  },
});

type Props = {
  children: JSX.Element;
  sections?: IDynamicSection[];
};

function Layout({ children, sections }: Props) {
  return (
    <Box>
      <Box position="fixed" top={0} w="100%" bg="white">
        <SiteHeader sections={sections} h={"siteHeader"} py={4} />
      </Box>
      <Box mt={["siteHeaderMobile", "siteHeaderDesktop"]}>{children}</Box>
      <Footer />
    </Box>
  );
}

export default Layout;
