import React from "react";
import { HStack, Box, Link, Icon, Wrap, WrapItem } from "@chakra-ui/react";

import { useSiteMetadata } from "../hooks/useSiteMetadata";

import {
  FaYoutube,
  FaTwitter,
  FaFacebook,
  FaSoundcloud,
  FaInstagram,
} from "react-icons/fa";

const iconSize = [8, 12];

type Props = {};

const DynamicSocialIcons = (props: Props) => {
  const { twitter, youtube, facebook, soundcloud, instagram, title } =
    useSiteMetadata();
  return (
    <Wrap spacing={4} align="center" justify="center" width="100%">
      <WrapItem>
        <Link href={youtube}>
          <Icon
            color="blackAlpha.800"
            as={FaYoutube}
            w={iconSize}
            h={iconSize}
          />
        </Link>
      </WrapItem>
      <WrapItem>
        <Link href={twitter}>
          <Icon
            color="blackAlpha.800"
            as={FaTwitter}
            w={iconSize}
            h={iconSize}
          />
        </Link>
      </WrapItem>
      <WrapItem>
        <Link href={facebook}>
          <Icon
            color="blackAlpha.800"
            as={FaFacebook}
            w={iconSize}
            h={iconSize}
          />
        </Link>
      </WrapItem>
      <WrapItem>
        <Link href={soundcloud}>
          <Icon
            color="blackAlpha.800"
            as={FaSoundcloud}
            w={iconSize}
            h={iconSize}
          />
        </Link>
      </WrapItem>
      <WrapItem>
        <Link href={instagram}>
          <Icon
            color="blackAlpha.800"
            as={FaInstagram}
            w={iconSize}
            h={iconSize}
          />
        </Link>
      </WrapItem>
    </Wrap>
  );
};

export default DynamicSocialIcons;
