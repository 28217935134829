import React from "react";
import { Image, Box, BoxProps } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImage } from "gatsby-plugin-storyblok-image";
import { StoryblokImage } from "../types/StoryblokImage";

type Props = {
  imgWrapperProps?: BoxProps;
  imgStyle?: React.CSSProperties;
  blok: {
    image: StoryblokImage;
    imageFit?: "cover" | "contain";
    circleCrop: boolean;
  };
};

function DynamicImage({ blok, imgWrapperProps, imgStyle }: Props) {
  const { imageFit, image, circleCrop } = blok;
  const isSvg = image?.filename.endsWith(".svg");

  const gatsbyImageData = isSvg
    ? null
    : getGatsbyImage(image?.filename, {
        layout: "constrained",
        quality: 80,
      });

  return (
    <Box
      {...imgWrapperProps}
      borderRadius={circleCrop ? "full" : undefined}
      overflow="hidden"
    >
      {gatsbyImageData ? (
        <GatsbyImage
          alt={image.alt}
          image={gatsbyImageData}
          objectFit={imageFit}
          style={{ height: "100%" }}
          imgStyle={imgStyle}
        />
      ) : (
        <Image src={image.filename} alt={image.alt} />
      )}
    </Box>
  );
}

export default DynamicImage;
