import { SbBlokData, storyblokEditable } from "gatsby-source-storyblok";
import React from "react";
import RichText from "./RichText";
import { Box, Center } from "@chakra-ui/react";

type Props = {
  blok: SbBlokData & {
    textAlignment?: "center" | "left" | "right";
    centerContent?: boolean;
    textStyle: string;
  };
};

function TextBlock({ blok }: Props) {
  const Component = blok.centerContent ? Center : Box;
  return (
    <Component {...storyblokEditable(blok)} textStyle={blok.textStyle || "m"}>
      <RichText body={blok.body} textAlignment={blok.textAlignment} />
    </Component>
  );
}

export default TextBlock;
