import { SbBlokData, StoryblokComponent } from "gatsby-source-storyblok";
import { Container } from "@chakra-ui/react";
import React from "react";

type SizeOption =
  | "default"
  | "xsmall"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge";

type Props = {
  blok: SbBlokData & {
    content: SbBlokData[];
    paddingX: SizeOption;
    paddingY: SizeOption;
    marginX: SizeOption;
    marginY: SizeOption;
    enforceMaxWidth: boolean;
    centerContent: boolean;
    centerSelf: boolean;
  };
};

function DynamicContainer({ blok }: Props) {
  return (
    <Container maxW="container.lg">
      {blok.content.map((content) => (
        <StoryblokComponent key={content._uid} blok={content} />
      ))}
    </Container>
  );
}

export default DynamicContainer;
