import React from "react";
import { Button } from "@chakra-ui/react";
import { SbBlokData, storyblokEditable } from "gatsby-source-storyblok";
import SmartLink from "./SmartLink";
import { StoryblokLink } from "../types/StoryblokLink";

type Props = {
  blok: SbBlokData & {
    text: string;
    link: StoryblokLink;
    fullWidth?: boolean;
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
    variant?: "solid" | "outline" | "ghost" | "link";
  };
};

function DynamicButton({ blok }: Props) {
  const {
    text,
    link,
    fullWidth = false,
    size = "md",
    variant = "solid",
  } = blok;
  return (
    <SmartLink to={link}>
      <Button
        size={size}
        variant={variant}
        colorScheme={"black"}
        width={fullWidth ? "100%" : undefined}
        {...storyblokEditable(blok)}
      >
        {text}
      </Button>
    </SmartLink>
  );
}

export default DynamicButton;
