import { SbBlokData, StoryblokComponent } from "gatsby-source-storyblok";
import { Center } from "@chakra-ui/react";
import React from "react";
import { toSpacingValue } from "../util/toSpacingValue";

type SizeOption =
  | "default"
  | "xsmall"
  | "small"
  | "medium"
  | "large"
  | "xlarge"
  | "xxlarge";

type Props = {
  blok: SbBlokData & {
    content: SbBlokData[];
    paddingX: SizeOption;
    paddingY: SizeOption;
    marginX: SizeOption;
    marginY: SizeOption;
  };
};

function DynamicCenter({ blok }: Props) {
  const { marginX, marginY, paddingX, paddingY } = blok;
  return (
    <Center
      mx={toSpacingValue(marginX)}
      my={toSpacingValue(marginY)}
      px={toSpacingValue(paddingX)}
      py={toSpacingValue(paddingY)}
    >
      {blok.content.map((content) => (
        <StoryblokComponent key={content._uid} blok={content} />
      ))}
    </Center>
  );
}

export default DynamicCenter;
