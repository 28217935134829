import React, { ReactNode } from "react";
import { Hide, Box } from "@chakra-ui/react";

type Props = {
  blok: {
    embedCode: string;
    hiddenOnMobile: boolean;
  };
};

const DynamicEmbeddableContent = ({ blok }: Props) => {
  const Wrapper = ({ children }: { children: ReactNode }) =>
    blok.hiddenOnMobile ? (
      <Hide below="sm">{children}</Hide>
    ) : (
      <Box>{children}</Box>
    );
  return (
    <Wrapper>
      <div dangerouslySetInnerHTML={{ __html: blok.embedCode }} />
    </Wrapper>
  );
};

export default DynamicEmbeddableContent;
