export function toSpacingValue(value: string): number[] {
  switch (value) {
    case "none":
      return [];
    case "small":
      return [2, 4];
    case "medium":
      return [4, 8];
    case "large":
      return [8, 16];
    case "xlarge":
      return [16, 24];
    case "xxlarge":
      return [24, 32];
    default:
      return [4, 8];
  }
}
