import React from "react";
import {
  Text,
  Heading,
  Image,
  ListItem,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import {
  NODE_HEADING,
  NODE_PARAGRAPH,
  NODE_IMAGE,
  NODE_UL,
  NODE_OL,
  MARK_BOLD,
  MARK_LINK,
} from "storyblok-rich-text-react-renderer";

import SmartLink from "../components/SmartLink";
import { StoryblokLink } from "../types/StoryblokLink";
import { TextType } from "../types/TextType";

type HeadingLevels = 1 | 2 | 3 | 4 | 5 | 6;

const getHeadingTag = (level: HeadingLevels): keyof JSX.IntrinsicElements => {
  return `h${level}`;
};

const textStyles = {
  ".color-white": { color: "whiteAlpha.900" },
  ".color-yellow": { color: "brandYellow" },
  ".centered": { textAlign: "center" },
  ".sectionHeading": { fontWeight: 800, textTransform: "uppercase" },
};

const getHeadingSize = (level: HeadingLevels): string => {
  switch (level) {
    case 1:
      return "4xl";
    case 2:
      return "2xl";
    case 3:
      return "lg";
    case 4:
      return "md";
    case 5:
      return "sm";
    case 6:
      return "xs";
    default:
      return "4xl";
  }
};

export default {
  defaultStringResolver: (str: string) => <Text sx={textStyles}>{str}</Text>,
  nodeResolvers: {
    [NODE_PARAGRAPH]: (children: TextType) => {
      return children ? (
        <Text sx={textStyles} as="p">
          {children}
        </Text>
      ) : (
        <Text as="p">&nbsp;</Text>
      );
    },
    [NODE_IMAGE]: Image,
    [NODE_HEADING]: (
      children: JSX.Element,
      { level }: { level: HeadingLevels }
    ): JSX.Element => {
      return (
        <Heading
          size={getHeadingSize(level)}
          sx={textStyles}
          as={getHeadingTag(level)}
        >
          {children}
        </Heading>
      );
    },
    [NODE_UL]: (children: TextType[]) => {
      return (
        <UnorderedList>
          {children.map((x: TextType, i: number) => x)}
        </UnorderedList>
      );
    },
    [NODE_OL]: (
      children: (
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | React.ReactFragment
        | React.Key
        | null
        | undefined
      )[]
    ) => {
      return (
        <OrderedList>{children.map((x: TextType, i: number) => x)}</OrderedList>
      );
    },
  },
  markResolvers: {
    [MARK_BOLD]: (children: TextType) => <Text as="strong">{children}</Text>,
    [MARK_LINK]: (
      children: TextType,
      link: {
        href: string;
        target?: string;
        linktype: string;
        anchor?: string;
        uuid?: string;
      }
    ) => {
      return (
        <SmartLink
          to={{
            ...link,
            url: link.href,
            cached_url: link.href,
            fieldtype: "link",
            id: link.href,
          }}
          target={link.target}
        >
          {children}
        </SmartLink>
      );
    },
  },
};
