import React from "react";
import { PageProps, graphql } from "gatsby";
import {
  SbGatsbyStory,
  useStoryblokState,
  StoryblokComponent,
  SbBlokData,
  storyblokEditable,
} from "gatsby-source-storyblok";
import { VStack } from "@chakra-ui/react";

import Layout from "../components/Layout";
import { SEO } from "../components/Seo";

type Props = {
  storyblokEntry: SbGatsbyStory;
};

function PageTemplate({ data: { storyblokEntry } }: PageProps<Props>) {
  const story = useStoryblokState(storyblokEntry);

  const components = (story?.content?.body || []).map(
    (blok: SbBlokData, i: number) => (
      <StoryblokComponent blok={blok} key={blok._uid || i} />
    )
  );
  const sections = (story?.content?.body || []).filter(
    (s: SbBlokData) => s.component === "section"
  );

  return (
    <Layout sections={sections}>
      <div {...storyblokEditable(story.content)}>
        <VStack spacing={24}>{components}</VStack>
      </div>
    </Layout>
  );
}

export default PageTemplate;

export const Head = ({ data: { storyblokEntry } }: PageProps<Props>) => {
  const story = useStoryblokState(storyblokEntry);
  const title = story.content.seoTitle || storyblokEntry.name;

  return (
    <SEO
      title={title}
      description={story.content.seoDescription}
      imageUrl={story.content.seoImage?.filename}
    />
  );
};

export const query = graphql`
  query PageQuery($full_slug: String!) {
    storyblokEntry(full_slug: { eq: $full_slug }) {
      content
      id
      internalId
      name
    }
  }
`;
